import { useAccount, useWriteContract } from "wagmi";
import { readContract, waitForTransactionReceipt } from "@wagmi/core";
import { useState, useRef, useContext, useEffect } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";

import tokenAbi from "../../artifacts/contracts/token.sol/Token.json";
import stakingAbi from "../../artifacts/contracts/StakingAndDivident.sol/StakingAndDivident.json";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";
import { toWei } from "../../utils/helpers";
import { config } from "../../config";
import { SAIB_TOKEN_ABI_BNB, SAIB_TOKEN_ADDRESS_BSC, STAKE_ABI_BSC, STAKE_ADDRESS_BSC } from "../../utils/constants";
import { ethers, formatEther } from "ethers";

export const StakingViewModel = () => {
  let { data: hash, isPending, writeContract, variables, status } = useWriteContract();
  let { address } = useAccount();
  const [buyValue, setBuyValue] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [stakedDetails, setStakedDetails] = useState(null);
  const { staking_address, token_address } = useContext(ApplicationContext);

  const inputRef = useRef(null);

  useEffect(()=>{
    console.log(hash)
    if(hash){
    async function getReceipt(){
      const result = await waitForTransactionReceipt(config,{
        hash: hash,
      })
      console.log(result)
      if(result.status){
        iziToast.success({
          title: 'Succss',
          message: successMessage,
          position:"topRight"
      });
      }
    }
    getReceipt()
  }
  },[hash])

  useEffect(()=>{
    console.log("+++++++++++++++++++++++++Called",address)
    if(address){
      async function fetchData(){
        let stakedDetailsLcl = await getStakedDetails();
        setStakedDetails(stakedDetailsLcl)
      }
      fetchData()
    }
  },[address])

  const getApprovalAmount = async () => {
    const result = await readContract(config, {
      address: SAIB_TOKEN_ADDRESS_BSC,
      abi: SAIB_TOKEN_ABI_BNB,
      functionName: "allowance",
      args: [address, STAKE_ADDRESS_BSC],
    });
    return result;
  };

  const getStakedDetails = async() => {
    console.log("called")
    let stakedDetails = [];
    try {
      for(var i = 0; i < 1000; i++){
        const result = await readContract(config, {
          address: STAKE_ADDRESS_BSC,
          abi: STAKE_ABI_BSC,
          functionName: "stakedAmounts",
          args: [address,i],
        });
        let canUnstake = false;
        console.log(Number(Number(result[1]).toString()+"000"))
        console.log(new Date().getTime())
        console.log((Number(Number(result[1]).toString()+"000") + 31536000000))
        console.log(new Date((Number(Number(result[1]).toString()+"000") + 31536000000)).toDateString())
        if((Number(Number(result[1]).toString()+"000")+31536000000) < new Date().getTime()){
          canUnstake = true;
        }
        stakedDetails.push([formatEther(result[0]), new Date(Number(Number(result[1]).toString()+"000")).toLocaleDateString(), canUnstake, i])
      }
      console.log(stakedDetails)
      return stakedDetails;
    } catch(err){
      // console.log(err)
      return stakedDetails;
    } 
  };

  const approveToken = async (_amount) => {
    const amount = toWei(_amount);
    try {
      setSuccessMessage("Token Transfer Approved Successfully!");
      writeContract({
        address: SAIB_TOKEN_ADDRESS_BSC,
        abi: SAIB_TOKEN_ABI_BNB,
        functionName: "approve",
        args: [STAKE_ADDRESS_BSC, amount],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const stakeToken = async (_tokenAmount) => {
    const approveAmount = await getApprovalAmount();
    if (approveAmount < _tokenAmount) return alert("Tokens not approved");
    const amount = toWei(_tokenAmount);
    try {
      setSuccessMessage("Token Staked Successfully!");
      writeContract({
        address: STAKE_ADDRESS_BSC,
        abi: STAKE_ABI_BSC,
        functionName: "stake",
        args: [amount],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = async (event) => {
    if (address === undefined) return;
    const val = event.target.value;
    setBuyValue(val);
  };

  const unstakeToken = async (index) => {
    try {
      setSuccessMessage("Token Unstaked Successfully!");
      writeContract({
        address: STAKE_ADDRESS_BSC,
        abi: STAKE_ABI_BSC,
        functionName: "unstake",
        args: [index],
      });
    } catch (err) {
      console.log(err);
    }
  };

  return {
    approveToken,
    stakeToken,
    setBuyValue,
    handleInputChange,
    unstakeToken,
    buyValue,
    inputRef,
    stakedDetails
  };
};
